/** @format */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  faSpinner,
  faPlus,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import FlagIcon from '@assets/images/usa-flag.png';
import {
  Row,
  SpecialNeedsField,
  IconButton,
  LanguageSelectorDropdown,
  RiderTypeDropdown,
  QRCode,
  WorkplaceDropdownWorkforce,
} from '@components';

import { theme, generals } from '@constants';
import { helpers } from '@utils';
import { useFocusNextInput } from '@hooks';
import { OrganizationsDropdown } from './organizationsDropdown';
import {
  Wrapper,
  FormWrapper,
  NotInvitedText,
  Form,
  BottomWrapper,
  Organizations,
  OrganizationsTitle,
  Balance,
  BalanceText,
  DOBPicker,
  SInput,
  StyledButton,
  SLink,
  ButtonIcon,
  Tooltip,
  CreditWrapper,
} from './styled';
import config from './config';

const specialNeedsEnabled =
  process.env.REACT_APP_SPECIAL_NEEDS_ENABLED === 'true';

const workforceEnv = process.env.REACT_APP_WORKFORCE_APP === '1';

export const AccountSettings = ({
  state,
  loading,
  generateQRCodeLoading,
  validate,
  errors,
  organizations,
  invites,
  inviteLoading,
  selectedOrganization,
  selectedInvite,
  setOrganization,
  setInvite,
  inviteLoaderType,
  onChange,
  onInviteAccept,
  onInviteDecline,
  onSubmit,
  goToHome,
  organizationsForUsers,
  onChangeSpecialNeeds,
  onChangeDay,
  onChangeMonth,
  onChangeYear,
  goToSavedAddresses,
  goToPaymentMethods,
  balance,
  authQRCode,
  points,
  onChangeShowPassword,
  onChangeShowSpecialNeed,
  onChangeLanguage,
  onChangeRiderType,
  downloadQR,
  printQR,
  generateQR,
  workforceRiderApp,
  passwordRestricted,
  restrictedMode,
  goToLoadCredit,
  dynamicTextFields,
  workplaces,
  onChangeWorkplace,
  selectedWorkplace,
  largeScreen,
}) => {
  let organization;
  const { t } = useTranslation();
  const formFieldsForFocusNext = config.formFields.filter(o => o.focusNext);
  const [inputRefs, focusOnNextInput] = useFocusNextInput(
    formFieldsForFocusNext,
  );
  if (
    selectedOrganization !== null &&
    Array.isArray(organizations) &&
    selectedOrganization < organizations.length
  ) {
    const filterSelectedOrganization = organizations[selectedOrganization];
    organization = filterSelectedOrganization?.organization;
  }

  const allowLoadCredits =
    (
      organization?.bookingMethods ||
      organization?.detail?.bookingMethods ||
      []
    )?.includes('selfPaid') &&
    Array.isArray(organization?.selfPaymentOptions) &&
    organization?.selfPaymentOptions.length &&
    organization?.selfPaymentOptions.includes('card');

  useEffect(() => {
    if (Array.isArray(dynamicTextFields) && dynamicTextFields.length) {
      dynamicTextFields.forEach(tf => {
        const { label, type } = tf;
        const isRequiredField =
          type === generals.USER_DYNAMIC_FIELD_TYPES.REQUIRED;
        const fieldFound = config.formFields.find(
          field => field.name === helpers.camelize(label),
        );

        if (!fieldFound) {
          config.formFields.push({
            name: helpers.camelize(label),
            label: `${label} ${
              isRequiredField
                ? `(${t('profile.fields.dynamic-field.label.required')})`
                : ''
            }`,
            type: 'text',
            focusNext: true,
            fieldType: 'dynamic',
            validator: {
              required: isRequiredField
                ? t('profile.fields.dynamic-field.validation.required')
                : null,
            },
          });
          inputRefs.current.push(React.createRef());
        }
      });
    } else {
      config.formFields = (config.formFields || []).filter(
        field => field.fieldType !== 'dynamic',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicTextFields]);

  const togglePassword = () => {
    onChangeShowPassword(!state.showPassword);
  };

  const getInputIcon = formField => {
    return formField.name === 'password'
      ? { icon: state.showPassword ? faEyeSlash : faEye }
      : { icon: formField.name === 'phone' ? FlagIcon : null };
  };

  const getError = formField => {
    if (formField.name === 'password') {
      return t(helpers.getObjectProperty(errors, [formField.name, 'message']), {
        length: formField.validator.minLength.value,
      });
    }
    return t(helpers.getObjectProperty(errors, [formField.name, 'message']));
  };

  const getInputIconProps = formField => {
    return formField.name === 'password'
      ? {
          iconProps: {
            onClick: togglePassword,
          },
        }
      : {};
  };

  const getInputRef = (ref, formField) => {
    if (!ref) {
      return;
    }

    const index = formFieldsForFocusNext.findIndex(o => o.name === ref.name);

    inputRefs.current[index].current = ref;
    validate(ref, formField.validator);
  };

  const getFieldValue = (state, fieldName) => {
    if (state[fieldName] === undefined) {
      return state.detail[fieldName];
    }
    return state[fieldName];
  };

  const renderAccountSettingsForm = () => (
    <FormWrapper>
      <Form>
        {config.formFields.map((formField, i) => {
          if (formField.name === 'password') {
            let additonalConfigs = {};
            if (passwordRestricted) {
              additonalConfigs.validator = config.passwordValidator;
            }

            if (restrictedMode) {
              additonalConfigs = {
                ...additonalConfigs,
                autoFocus: true,
                style: {
                  borderColor: '#00bfff',
                },
              };
            }

            // eslint-disable-next-line no-param-reassign
            formField = { ...formField, ...additonalConfigs };
          }
          if (
            formField.name === 'dob' &&
            process.env.REACT_APP_DISABLE_DOB_FIELD === 'true'
          ) {
            config.formFields.splice(i, 1);
          }

          return formField.name === 'dob' ? (
            <DOBPicker
              id="date-of-birth"
              data-cy="date-of-birth"
              key={i}
              label={t(formField.label)}
              dob={state.dob}
              onChangeDay={onChangeDay}
              onChangeMonth={onChangeMonth}
              onChangeYear={onChangeYear}
              fontSize={18}
            />
          ) : (
            <SInput
              data-cy={helpers.camelToKebabCase(formField.name)}
              key={i}
              floating
              type={
                formField.name === 'password' && state.showPassword
                  ? 'text'
                  : formField.type
              }
              name={formField.name}
              onChange={onChange}
              onKeyPress={focusOnNextInput}
              value={getFieldValue(state, formField.name)}
              readOnly={!!formField.readOnly}
              autoFocus={formField.autoFocus}
              label={t(formField.label)}
              style={formField.style || {}}
              color={theme.COD_GRAY_LIGHT}
              inputRef={ref => getInputRef(ref, formField)}
              {...getInputIcon(formField)}
              {...getInputIconProps(formField)}
              error={getError(formField)}
            />
          );
        })}

        <LanguageSelectorDropdown
          onChangeLanguage={onChangeLanguage}
          userLanguage={state.language}
        />
        <RiderTypeDropdown
          id="account-setting-form-age-group"
          onChangeRiderType={onChangeRiderType}
          userRiderType={state.riderType}
        />
        {specialNeedsEnabled && (
          <SpecialNeedsField
            data-cy="special-need-dropdown"
            onChange={onChangeSpecialNeeds}
            specialNeeds={state.specialNeeds}
            showSpecialNeed={state.showSpecialNeed}
            onChangeShowSpecialNeed={onChangeShowSpecialNeed}
          />
        )}
      </Form>
      <BottomWrapper>
        <StyledButton
          data-cy="saved-addresses"
          id="saved-addresses-btn"
          onClick={goToSavedAddresses}
          label={t('profile.buttons.saved-addresses')}
          icon={faPlus}
          color={theme.BASE_COLOR}
          inverted
          bordered
        />
      </BottomWrapper>
      {!workforceRiderApp && (
        <BottomWrapper>
          <StyledButton
            disabled={selectedOrganization === null}
            data-cy="add-payment-methods"
            id="add-payment-methods-btn"
            onClick={goToPaymentMethods}
            label={t('profile.buttons.add-payment-methods')}
            icon={faPlus}
            color={theme.BASE_COLOR}
            inverted
            bordered
          />
        </BottomWrapper>
      )}
      <BottomWrapper>
        <StyledButton
          data-cy="update-btn"
          id="update-btn"
          onClick={onSubmit}
          label={t('profile.buttons.update-account')}
          color={theme.BASE_COLOR}
          icon={loading ? faSpinner : null}
          iconProps={{ spin: true }}
          disabled={loading}
        />
      </BottomWrapper>
    </FormWrapper>
  );

  return (
    <Wrapper>
      <Row flex="initial" align="flex-end" justify="flex-end">
        <IconButton id="close-button" onClick={goToHome} />
      </Row>
      {allowLoadCredits && (
        <CreditWrapper id="load-credits-wrapper">
          <Row flex="initial" align="flex-start" justify="flex-start">
            <BalanceText>
              <Balance>{t('profile.labels.credits')}: </Balance>
              {`$${parseFloat(balance).toFixed(2)}`}
            </BalanceText>
            <SLink>
              <Tooltip
                id="cost-tooltip"
                multiline
                backgroundColor={theme.WEIRD_GREEN}
                textColor={theme.WHITE}
              />
              <span
                data-tip={t('load-credits.tooltip')}
                data-event="mouseover"
                data-event-off="mouseout"
                data-for="cost-tooltip"
                data-place="right"
                data-effect="solid"
              >
                <ButtonIcon
                  id="load-credits-button"
                  icon={faPlus}
                  data-cy="load-credits-button"
                  onClick={goToLoadCredit}
                />
              </span>
            </SLink>
          </Row>
        </CreditWrapper>
      )}
      {organizationsForUsers && (
        <Row flex="initial" align="flex-start" justify="flex-start">
          <Organizations id="organizations-text">
            <OrganizationsTitle>
              {t('profile.labels.organizations')}:{' '}
            </OrganizationsTitle>
            {organizationsForUsers}
          </Organizations>
        </Row>
      )}

      {!organizations && !inviteLoading && invites.length === 0 && (
        <Row flex="initial" align="flex-start" justify="flex-start">
          <NotInvitedText id="not-invited-text">
            {t('profile.labels.not-invited')}
          </NotInvitedText>
        </Row>
      )}

      <OrganizationsDropdown
        data-cy="organization-dropdown"
        invites={invites}
        organizations={organizations || []}
        selectedOrganization={selectedOrganization}
        selectedInvite={selectedInvite}
        setOrganization={setOrganization}
        setInvite={setInvite}
        accept={onInviteAccept}
        decline={onInviteDecline}
        loading={inviteLoading}
        inviteLoaderType={inviteLoaderType}
      />

      {workforceEnv && <WorkplaceDropdownWorkforce
        workplaces={workplaces}
        onChangeWorkplace={onChangeWorkplace}
        selectedWorkplace={selectedWorkplace}
      />}

      <QRCode
        authQRCode={authQRCode}
        downloadQR={downloadQR}
        printQR={printQR}
        generateQR={generateQR}
        loading={generateQRCodeLoading}
        largeScreen={largeScreen}
      />

      <Row flex="initial" align="flex-start" justify="flex-start">
        {(organization?.bookingMethods || organization?.detail?.bookingMethods || [])?.includes('points') ? (
          <BalanceText>
            <Balance>{t('profile.labels.points')}: </Balance>
            {`${points}`}
          </BalanceText>
        ) : undefined}
      </Row>
      {renderAccountSettingsForm()}
    </Wrapper>
  );
};

AccountSettings.propTypes = {
  state: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dob: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    phone: PropTypes.string,
    riderType: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    savedAddresses: PropTypes.array,
    specialNeeds: PropTypes.array,
    notes: PropTypes.string,
    showPassword: PropTypes.bool,
    showSpecialNeed: PropTypes.string,
    detail: PropTypes.object,
    language: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
  organizations: PropTypes.array,
  invites: PropTypes.array.isRequired,
  inviteLoading: PropTypes.bool.isRequired,
  selectedOrganization: PropTypes.number,
  selectedInvite: PropTypes.string,
  setOrganization: PropTypes.func.isRequired,
  setInvite: PropTypes.func.isRequired,
  inviteLoaderType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChangeSpecialNeeds: PropTypes.func.isRequired,
  onInviteAccept: PropTypes.func.isRequired,
  onInviteDecline: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goToHome: PropTypes.func.isRequired,
  organizationsForUsers: PropTypes.string,
  onChangeDay: PropTypes.func.isRequired,
  onChangeMonth: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  goToSavedAddresses: PropTypes.func.isRequired,
  goToPaymentMethods: PropTypes.func.isRequired,
  balance: PropTypes.string.isRequired,
  points: PropTypes.number,
  authQRCode: PropTypes.string,
  onChangeShowPassword: PropTypes.func.isRequired,
  onChangeShowSpecialNeed: PropTypes.func.isRequired,
  onChangeLanguage: PropTypes.func.isRequired,
  onChangeRiderType: PropTypes.func.isRequired,
  workforceRiderApp: PropTypes.bool.isRequired,
  passwordRestricted: PropTypes.bool.isRequired,
  restrictedMode: PropTypes.bool,
  goToLoadCredit: PropTypes.func.isRequired,
  dynamicTextFields: PropTypes.array.isRequired,
  generateQRCodeLoading: PropTypes.func.isRequired,
  downloadQR: PropTypes.func.isRequired,
  printQR: PropTypes.func.isRequired,
  generateQR: PropTypes.func.isRequired,
  workplaces: PropTypes.array.isRequired,
  onChangeWorkplace: PropTypes.func.isRequired,
  selectedWorkplace: PropTypes.string.isRequired,
  largeScreen: PropTypes.bool.isRequired,
};

AccountSettings.defaultProps = {
  organizations: null,
  selectedOrganization: null,
  selectedInvite: null,
  inviteLoaderType: null,
  organizationsForUsers: null,
  points: 0,
  restrictedMode: false,
  authQRCode: null,
};

export default AccountSettings;
