/** @format */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, LanguageSelectorDropdown, EmailContactModal } from '@components';
import { generals as generalConstants } from '@constants';
import {
  Header as SHeader,
  LogoImg,
  NavBarItem,
  DropdownWrapper,
  Banner,
  BannerText,
  NoOrganizationChatLink,
  LinkTag,
  NavBarItemSmall,
  SDropdown,
  MenuItem,
  StyledMenu,
  StyledBurger,
  PaddedDiv,
} from './styled';
import { helpers } from '@utils';

// env variables
const logo = process.env.REACT_APP_LOGO_URL || generalConstants.SHARE_LOGO;
const chatEnabled = !!process.env.REACT_APP_CHAT_URL;
const dispatchEmail = process.env.REACT_APP_DISPATCH_EMAIL;
const contactUrl = process.env.REACT_APP_CONTACT_URL;
const scheduleUrl = process.env.REACT_APP_SCHEDULE_URL;

const Header = ({
  largeScreen,
  isAuthenticated,
  onClickSignup,
  onClickLogin,
  onClickProfile,
  onClickRides,
  onClickGoals,
  logout,
  currentPath,
  riders,
  selectedRider,
  onSelectRider,
  orgDetails,
  organizations,
  invites,
  isUserAgent,
  onScheduleClicked,
  onContactCliked,
  isPasswordlessAuthenticated,
  openSendReferralCodeDialog,
  selectedOrgWithDetails,
  onChangeLanguage,
  language,
  workforceRiderApp,
  showEmailModal,
  onToggleEmailModal,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const showChillicotheBanner = process.env.REACT_APP_SHOW_CHILLICOTHE_BANNER;
  const ridershipBonusEnded = true;
  const location = useLocation();

  useEffect(() => {
    const checkIfClickedOutside = e => {

      // Close the menu if clicked outside
      if (ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [ref])

  const getRiderName = (rider) => {
    if (!largeScreen && rider.length > 8) {
       return rider?.replace(/ .*/, '');
    }
    return rider?.replace('undefined', '');
  };
  const onClickLanguageDropdown = () => {
      if(isMenuOpen){
        setIsMenuOpen(false);
      }
      setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  }
  const onClickMenu = () => {
      if(isLanguageDropdownOpen){
        setIsLanguageDropdownOpen(false);
      }
      setIsMenuOpen(!isMenuOpen);
  }

  const riderOptions = riders.map(rider => rider?.replace('undefined', ''));

  const ridersDropdown = (
    riders && riders.length > 0 && (
      <DropdownWrapper id="rider-wrapper">
        <SDropdown
          id="riders-list"
          onChange={onSelectRider}
          options={riderOptions}
          value={getRiderName(riders[selectedRider])}
          inverted
          size="sm"
        />
      </DropdownWrapper>
    )
  );

  const openChat = () => {
    window.zE('webWidget', 'toggle');
  };

  const goToAccountSettings = () => {
    history.push('/account-settings');
  };

  const getNoOrgBannerText = () => {
    if (invites.length > 0) {
      return t('header.no-organization-banner.pending-invites');
    }

    if (chatEnabled) {
      return t('header.no-organization-banner.no-invites.chat-enabled');
    }

    return t('header.no-organization-banner.no-invites.chat-disabled');
  };

  const renderNoOrganizationLink = () => {
    if (invites.length > 0) {
      return (
        <NoOrganizationChatLink onClick={goToAccountSettings}>
          {t('common.click-here')}
        </NoOrganizationChatLink>
      );
    }

    if (chatEnabled) {
      return (
        <NoOrganizationChatLink onClick={openChat}>
          {t('common.click-here')}
        </NoOrganizationChatLink>
      );
    }

    return (
      <NoOrganizationChatLink href={`mailto:${dispatchEmail}`}>
        {dispatchEmail}
      </NoOrganizationChatLink>
    );
  };

  const renderNoOrgBanner = () => {
    if (isUserAgent ||
      !isAuthenticated ||
      (Array.isArray(organizations) && organizations.length !== 0) ||
      isPasswordlessAuthenticated) {
      return null;
    }

    return (
      <Banner id="no-org-banner" flex="initial" justify="center" align="center">
        <BannerText>
          {getNoOrgBannerText()}

          {renderNoOrganizationLink()}
        </BannerText>
      </Banner>
    );
  };

  const renderChillicotheBanner = () => {
    if ((isAuthenticated && !organizations?.length) || !showChillicotheBanner) {
      return null;
    }

    return (
      <Banner id="chillicothe-banner" flex="initial" justify="center" align="center">
        <BannerText>
          {t('header.chillicothe-banner')}

          <NoOrganizationChatLink target="_blank" href="https://www.chillicotheoh.gov/departments/transit_department/trolley.php">
            {t('common.click-here')}
          </NoOrganizationChatLink>
        </BannerText>
      </Banner>
    );
  };

  const handleContact = () => {
    if (helpers.isEmailAddress(contactUrl)) {
      onToggleEmailModal(true);
      window.open(`mailto:${contactUrl}`, '_blank');
    } else {
      window.open(contactUrl, '_blank');
      if (onContactCliked) {
        onContactCliked();
      }
    }
  };

  const handleProfile = () => {
    history.push('/account-settings');
  };

  const renderContactItem = (isSmall = false) => {
    const Component = isSmall ? NavBarItemSmall : NavBarItem;
    return (
      <>  
        {isAuthenticated ? <Component
          data-cy="nav-contact-link"
          onClick={handleProfile}
          id="profile"
        >
          {t('header.buttons.profile')}
        </Component> : null}
        <Component
          data-cy="nav-contact-link"
          onClick={handleContact}
          id="contact"
        >
          {t('header.buttons.contact')}
        </Component>
      </>
    );
  };

  return (
    <>
      {showEmailModal && (
        <EmailContactModal
          toggle={() => onToggleEmailModal(false)}
          email={contactUrl}
        />
      )}

      {renderNoOrgBanner()}
      {renderChillicotheBanner()}
      <SHeader largeScreen={largeScreen} showHeaderBoxShadow={workforceRiderApp} ref={ref}>
        {/* {!largeScreen && <NavIcon position="left" icon={faUser} />} */}
        <Link to="/">
          <LogoImg data-cy="home-logo" src={logo} alt="Share Mobility Logo" />
        </Link>
        {!isPasswordlessAuthenticated && (
          <>
            {largeScreen ? (
              <div>
                <Row align="center" justify="flex-start" spacingH={15}>
                  {!isAuthenticated ? (
                    <>
                      <NavBarItem
                        data-cy="nav-signup-link"
                        active={currentPath === '/signup'}
                        onClick={onClickSignup}
                        id="signup"
                      >
                        {t('header.buttons.signup')}
                      </NavBarItem>
                      <NavBarItem
                        data-cy="nav-login-link"
                        active={currentPath === '/login'}
                        onClick={onClickLogin}
                        id="login"
                      >
                        {t('header.buttons.login')}
                      </NavBarItem>
                    </>
                  ) : (
                    <>
                      {ridersDropdown}
                      {organizations?.length &&
                      selectedOrgWithDetails?.detail?.ridershipBonus?.enabled &&
                      !ridershipBonusEnded ? (
                        <Link to="/riderbonus">
                          <NavBarItem
                            data-cy="nav-ridership-bonus-link"
                            active={currentPath === '/riderbonus'}
                            id="ridership-bonus"
                          >
                            {t('ridership-bonus.heading')}
                          </NavBarItem>
                        </Link>
                      ) : null}
                      {!location.pathname.includes('fixed-route') ? <Link to="/my-trips">
                        <NavBarItem
                          data-cy="nav-my-rides-link"
                          active={currentPath === '/my-trips'}
                          id="my-trips"
                        >
                          {t('header.buttons.my-rides')}
                        </NavBarItem>
                      </Link> : null}
                      {scheduleUrl && (
                        <LinkTag href={scheduleUrl} target="_blank">
                          <NavBarItem
                            data-cy="nav-schedule-link"
                            onClick={() =>
                              onScheduleClicked({
                                link: scheduleUrl,
                              })}
                            id="shedule-url"
                          >
                            {t('header.buttons.schedule')}
                          </NavBarItem>
                        </LinkTag>
                      )}

                      {organizations?.length && selectedOrgWithDetails?.detail?.referral && !location.pathname.includes('fixed-route') ? (
                        <MenuItem
                          onClick={openSendReferralCodeDialog}
                          id="referral-friend-btn"
                          data-cy="referal-friends-btn"
                        >
                          {t('send-referrals.labels.title')}
                        </MenuItem>
                      ) : null}

                      {/* <NavBarItem
                    onClick={onClickGoals}
                    active={currentPath === '/work-in-progress'}
                  >
                    Goals
                  </NavBarItem> */}

                      {/* <Link to="/account-settings">
                        <NavBarItem
                          data-cy="nav-profile-link"
                          active={currentPath === '/account-settings'}
                          id="account-settings"
                        >
                          {t('header.buttons.profile')}
                        </NavBarItem>
                      </Link> */}
                      {process.env.REACT_APP_PUBLIC_MAP_LINK && (
                        <a id="public-map-link" href={process.env.REACT_APP_PUBLIC_MAP_LINK} target="_blank" rel="noopener noreferrer">
                          <NavBarItem
                            data-cy="nav-public-map-link"
                          >
                            {t('header.buttons.public-map')}
                          </NavBarItem>
                        </a>
                      )}
                      <NavBarItem
                        data-cy="nav-logout-link"
                        id="logout-button"
                        onClick={logout}
                      >
                        {t('header.buttons.logout')}
                      </NavBarItem>
                    </>
                  )}
                  <NavBarItem dropdown>
                    <LanguageSelectorDropdown
                      id="language-selector"
                      onChangeLanguage={onChangeLanguage}
                      userLanguage={language}
                      inHeader
                      showFlag={false}
                    />
                  </NavBarItem>
                  {contactUrl && renderContactItem()}
                </Row>
              </div>
            ) : (
              <Row justify="flex-end" align="center">
                <NavBarItemSmall dropdown>
                  <LanguageSelectorDropdown
                    id="language-selector-sm"
                    onChangeLanguage={onChangeLanguage}
                    userLanguage={language}
                    inHeader
                    largeScreen={false}
                    showFlag={false}
                    onClick={onClickLanguageDropdown}
                    isOpen={!isLanguageDropdownOpen}
                    setIsLanguageDropdownOpen={setIsLanguageDropdownOpen}
                    isSmallScreen={!largeScreen}
                  />
                </NavBarItemSmall>
                {isAuthenticated && ridersDropdown}
                <StyledBurger open={isMenuOpen} onClick={onClickMenu}>
                  <PaddedDiv />
                  <PaddedDiv />
                  <PaddedDiv />
                </StyledBurger>
                <StyledMenu open={isMenuOpen} onClick={() => setIsMenuOpen(false)}>
                  {workforceRiderApp && (
                    isAuthenticated ? (
                      <>
                        {!location.pathname.includes('fixed-route') ? <Link to="/my-trips">
                          <NavBarItemSmall
                            data-cy="nav-my-rides-link-small"
                            active={currentPath === '/my-trips'}
                            onClick={onClickRides}
                          >
                            {t('header.buttons.my-rides')}
                          </NavBarItemSmall>
                        </Link> : null}
                        {isAuthenticated ? <NavBarItemSmall
                          data-cy="nav-profile-link-small"
                          active={currentPath === '/account-settings'}
                          onClick={onClickProfile}
                        >
                          {t('header.buttons.profile')}
                        </NavBarItemSmall> : null}
                        {!location.pathname.includes('fixed-route') ? <NavBarItemSmall
                          data-cy="nav-send-referrals-link-small"
                          onClick={openSendReferralCodeDialog}
                        >
                          {t('send-referrals.labels.title')}
                        </NavBarItemSmall> : null}
                      </>
                    ) : (
                      <>
                        <NavBarItemSmall
                          data-cy="nav-signup-link-small"
                          active={currentPath === '/signup'}
                          onClick={onClickSignup}
                        >
                          {t('header.buttons.signup')}
                        </NavBarItemSmall>
                        <NavBarItemSmall
                          data-cy="nav-login-link-small"
                          active={currentPath === '/login'}
                          onClick={onClickLogin}
                        >
                          {t('header.buttons.login')}
                        </NavBarItemSmall>
                      </>
                    )
                  )}
                  {isAuthenticated && (
                    <>
                      {process.env.REACT_APP_PUBLIC_MAP_LINK && (
                        <a href={process.env.REACT_APP_PUBLIC_MAP_LINK} target="_blank" rel="noopener noreferrer">
                          <NavBarItemSmall
                            data-cy="nav-public-map-link"
                          >
                            {t('header.buttons.public-map')}
                          </NavBarItemSmall>
                        </a>
                      )}
                      {scheduleUrl && (
                        <NavBarItemSmall
                          onClick={() =>
                            onScheduleClicked({
                              link: scheduleUrl,
                            })}
                        >
                          <LinkTag
                            data-cy="nav-schedule-link"
                            href={scheduleUrl}
                            target="_blank"
                          >
                            {t('header.buttons.schedule')}
                          </LinkTag>
                        </NavBarItemSmall>
                      )}
                    </>
                  )}
                  {organizations?.length &&
                  selectedOrgWithDetails?.detail?.ridershipBonus?.enabled &&
                  !ridershipBonusEnded ? (
                    <Link to="/riderbonus">
                      <NavBarItemSmall
                        data-cy="nav-ridership-bonus-link-small"
                        active={currentPath === '/riderbonus'}
                      >
                        {t('ridership-bonus.heading')}
                      </NavBarItemSmall>
                    </Link>
                  ) : null}
                  {contactUrl && renderContactItem(true)}
                  {isAuthenticated && (
                    <NavBarItemSmall
                      data-cy="nav-logout-link"
                      id="logout-button-small"
                      onClick={logout}
                    >
                      {t('header.buttons.logout')}
                    </NavBarItemSmall>
                  )}
                </StyledMenu>
              </Row>
            )}
          </>
        )}
        {/* <NavIcon position="right" icon={faUser} size="2x" /> */}
      </SHeader>
    </>
  );
};

Header.propTypes = {
  largeScreen: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  onClickSignup: PropTypes.func.isRequired,
  onClickLogin: PropTypes.func.isRequired,
  onClickProfile: PropTypes.func.isRequired,
  onClickRides: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired,
  onClickGoals: PropTypes.func.isRequired,
  riders: PropTypes.array.isRequired,
  selectedRider: PropTypes.number.isRequired,
  onSelectRider: PropTypes.func.isRequired,
  orgDetails: PropTypes.object,
  organizations: PropTypes.array,
  invites: PropTypes.array.isRequired,
  isUserAgent: PropTypes.bool,
  onScheduleClicked: PropTypes.func,
  onContactCliked: PropTypes.func,
  isPasswordlessAuthenticated: PropTypes.bool.isRequired,
  openSendReferralCodeDialog: PropTypes.func.isRequired,
  selectedOrgWithDetails: PropTypes.object,
  onChangeLanguage: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  workforceRiderApp: PropTypes.bool.isRequired,
  showEmailModal: PropTypes.bool.isRequired,
  onToggleEmailModal: PropTypes.func.isRequired,
};

Header.defaultProps = {
  organizations: null,
  orgDetails: null,
  isUserAgent: false,
  onScheduleClicked: null,
  onContactCliked: null,
  selectedOrgWithDetails: null,
};

export default Header;
