/** @format */

import styled from 'styled-components';

import { styles as styleUtils } from '@utils';
import { styles, theme, device } from '@constants';
import { Row, Dropdown } from '..';

// env variables
const headerHeight = Number(process.env.REACT_APP_HEADER_HEIGHT || 40);

export const Banner = styled(Row)`
  width: 100%;
  background-color: ${theme.TOMATO};
  position: relative;
  z-index: 32;
  height: ${styles.NO_ORG_BANNER_HEIGHT}px;
`;

export const BannerText = styled.span`
  color: ${theme.WHITE};
  text-align: center;
  padding: 0 8px;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 10px;
  }
`;

export const SDropdown = styled(Dropdown)`
  .rdd-menu {
    top: ${headerHeight - 8}px;
  }
  @media ${device.mediaQuery.SMALLEST_SCREEN} {
    width: 112px;
  }
`;

export const NoOrganizationChatLink = styled.a`
  color: ${theme.WHITE};
  text-align: center;
  text-decoration: underline;
  cursor:pointer;
  @media ${device.mediaQuery.SMALL_SCREEN} {
    font-size: 10px;
  }

  &:hover,
  &:focus {
    color: ${theme.WHITE};
  }
`;

export const Header = styled.div`
  width: 100%;
  height: ${headerHeight}px;
  background-color: ${theme.WHITE};
  position: relative;
  box-shadow: ${props =>
    props.showHeaderBoxShadow &&
    `0 0 4px 0 ${styleUtils.hexToRgba(theme.BLACK, 0.16)}`};
  a:hover {
    color: ${theme.BASE_COLOR};
    text-decoration: none;
  }

  z-index: 111!important;
  ${props => {
    return props.largeScreen
      ? styleUtils.flex('space-between', 'center')
      : styleUtils.flex('space-between', 'center');
  }};

  .Dropdown-root {
    height: ${headerHeight}px;
  }
`;

export const LogoImg = styled.img`
  height: ${headerHeight}px;
  max-width: 120px;
  object-fit: contain;

  @media ${device.mediaQuery.LARGE_SCREEN} {
    margin-left: 20px;
  }

  ${headerHeight === 60 &&
  `
    height: 100px;
    top: 5px;
    position: relative;
    `}
`;

export const NavBarItem = styled.span`
  margin: ${props => (!props.dropdown ? '0 20px' : '0')};
  white-space: nowrap;
  color: ${theme.BASE_COLOR};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  cursor: pointer;
  &:hover {
    color: ${theme.BASE_COLOR};
  }
`;

export const NavBarItemSmall = styled(NavBarItem)`
  margin: 0 10px 0 0;
  font-size: 16px;
  padding: 10px 0 10px 0;
`;

export const DropdownWrapper = styled.div`
  @media ${device.mediaQuery.SMALL_SCREEN} {
    margin-right: 10px;
    margin-left: 10px;
  }

  height: ${headerHeight}px;
`;

export const LinkTag = styled.a`
  color: ${theme.BASE_COLOR};
`;

export const MenuItem = styled.p`
  margin: 0 20px;
  white-space: nowrap;
  color: ${theme.BASE_COLOR};
  font-weight: bold; // @NOTE: for new feature
  cursor: pointer;
  &:hover {
    color: ${theme.BASE_COLOR};
  }
  font-size: 16px;
`;

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-self: flex-end;
  justify-content: space-around;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-100%)')};
  text-align: left;
  box-shadow: ${({ open }) => open && '0 4px 0 0 rgba(0, 0, 0, 0.15)'};
  background: ${theme.WHITE};
  padding: 15px;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: ${({ open }) => open && '3rem'};
  transition: transform 0.3s ease-in-out;
  margin-top: ${({ open }) => open && headerHeight === 60 && '10px'};
  z-index: 1;
  }
`;

export const PaddedDiv = styled.div`
  margin: 2px;
`;

export const StyledBurger = styled.button`
  top: 5%;
  left: 2rem;
  display: flex;
  flex-direction: column;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  margin: 0 20px 0 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 1.4rem;
    height: 0.2rem;
    background: ${theme.BASE_COLOR};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
