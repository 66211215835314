/** @format */

import React from 'react';
import { PropTypes } from 'prop-types';
import { defaultCenter } from './config';
import FixedRouteSearchForm from './searchForm/index';
import FixedRouteMap from './fixedRouteMap';
import FixedRouteCards from './fixedRouteCards';
import { fixedRouteHelpers } from '@utils';

const FixedRoutePublicMap = ({
  fixedRoute,
  currentLocation,
  fixedRoutes,
  vehicleLocation,
  onChangeFixedRoute,
  largeScreen,
  onChange,
  state,
  loading,
  disabled,
  onSelectDate,
  onSelectTime,
  onToggleSearchAll,
  onToggleType,
  onChangeResult,
  handleSubmit,
  onClear,
  clearAddresses,
  getAddresses,
  onPickupSelected,
  onDropoffSelected,
  goToHome,
  toggleMinimized,
  toggleCardVisible,
  onOpenPicker,
  hideFixRoutePlanner,
}) => {
  const center = currentLocation || defaultCenter;
  const getDropdownOptions = fixedRoutes => {
    return (
      fixedRoutes &&
      fixedRoutes.map(fr => ({
        value: fr.id,
        label: fr.name,
      }))
    );
  };

  const { fixedRouteCardResults, searchResultSteps } =
    fixedRouteHelpers.fixedRouteSearchResult(state);
  const getselectedCard = id => {
    onChangeResult(id);
  };
  return (
    <>
      {!largeScreen ? (
        <FixedRouteMap
          center={center}
          fixedRoute={fixedRoute}
          vehicleLocation={vehicleLocation}
          searchResult={searchResultSteps}
        />
      ) : null}
      {!hideFixRoutePlanner && (
        <FixedRouteSearchForm
          largeScreen={largeScreen}
          onChange={onChange}
          state={state}
          loading={loading}
          disabled={disabled}
          onSelectDate={onSelectDate}
          onSelectTime={onSelectTime}
          onToggleSearchAll={onToggleSearchAll}
          onToggleType={onToggleType}
          handleSubmit={handleSubmit}
          onClear={onClear}
          clearAddresses={clearAddresses}
          getAddresses={getAddresses}
          onPickupSelected={onPickupSelected}
          onDropoffSelected={onDropoffSelected}
          goToHome={goToHome}
          disableFixedRoutesDropdown={
            fixedRoutes.length > 0 && !state.query.searchInAllFixedRoutes
          }
          onChangeFixedRoute={onChangeFixedRoute}
          dropdownOptions={getDropdownOptions(fixedRoutes)}
          dropdownValue={fixedRoute.name}
          toggleMinimized={toggleMinimized}
          toggleCardVisible={toggleCardVisible}
          onOpenPicker={onOpenPicker}
        />
      )}
      {largeScreen ? (
        <FixedRouteMap
          center={center}
          fixedRoute={fixedRoute}
          vehicleLocation={vehicleLocation}
          searchResult={searchResultSteps}
        />
      ) : null}
      <FixedRouteCards
        fixedRouteResults={fixedRouteCardResults}
        selectFixedRouteResult={getselectedCard}
        toggleCardVisible={toggleCardVisible}
        state={state}
        largeScreen={largeScreen}
        toggleMinimized={toggleMinimized}
      />
    </>
  );
};

FixedRoutePublicMap.propTypes = {
  fixedRoute: PropTypes.shape({
    path: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        lat: PropTypes.number,
        lng: PropTypes.number,
        seq: PropTypes.number,
        pathToNextStop: PropTypes.array,
      }),
    ),
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  currentLocation: PropTypes.array,
  fixedRoutes: PropTypes.array,
  vehicleLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  onChangeFixedRoute: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  state: PropTypes.shape({
    query: PropTypes.shape({
      date: PropTypes.string,
      searchInAllFixedRoutes: PropTypes.bool,
      time: PropTypes.string,
      pickup: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      dropoff: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      type: PropTypes.string,
    }).isRequired,
    minimized: PropTypes.bool.isRequired,
    pickupSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
    dropoffSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  onSelectDate: PropTypes.func.isRequired,
  onSelectTime: PropTypes.func.isRequired,
  onToggleSearchAll: PropTypes.func.isRequired,
  onToggleType: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  getAddresses: PropTypes.func.isRequired,
  clearAddresses: PropTypes.func.isRequired,
  onPickupSelected: PropTypes.func.isRequired,
  onDropoffSelected: PropTypes.func.isRequired,
  goToHome: PropTypes.func.isRequired,
  onChangeResult: PropTypes.func.isRequired,
  toggleMinimized: PropTypes.func.isRequired,
  onOpenPicker: PropTypes.func.isRequired,
};

FixedRoutePublicMap.defaultProps = {
  currentLocation: null,
  loading: null,
  fixedRoutes: [],
  hideFixRoutePlanner: false,
};

export default FixedRoutePublicMap;
